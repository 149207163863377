export const ShopCode = {
    FVDE: 'fvde',
    FVAT: 'fvat',
    FVCH: 'fvch',
    FVFR: 'fvfr',
    FVBE: 'fvbe',
    FVIN: 'fvin',
    FVIT: 'fvit',
    PNDE: 'pnde',
    HTDE: 'htde',
    FVES: 'fves',
};

export const ShopId = {
    FVDE: 1,
    FVAT: 7,
    FVCH: 6,
    FVFR: 2,
    FVBE: 10,
    FVIN: 5,
    FVIT: 11,
    PNDE: 8,
    HTDE: 9,
    FVES: 12,
};

export const globals = {
    shopShortCode:        window.shopShortcode,
    shopId:               window.shopId,
    shopIsEu:             window.shopIsEu,
    baseUrl:              window.baseUrl,
    appLocale:            window.appLocale,
    numberFormatSettings: window.numberFormatSettings,
    googleWebAppId:       window.googleWebAppId,
};

export enum ModalMessageType {
    error = 'danger',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    danger = 'danger',

    warning = 'warning',

    primary = 'success',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    success = 'success',

    info = 'info',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    default = 'info',
}

export interface DataTableColumn {
    /**
     * Identifier for header column
     */
    key: string;
    /**
     * The label for the column
     */
    label: string;
    /**
     * Can be used to format output for the cells in this column
     */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    format?: Function;
    /**
     * If true, this column will not be shown in the table
     */
    hidden?: boolean;
    /**
     * If true, the desktop view will show cells for this column on its own full-width line
     */
    expanded?: boolean;
    /**
     *
     */
    useKeyAsRowClass?: boolean;
}

export interface DataTableItem {
    /**
     * Unique identifier for the row
     */
    id: number;
    /**
     * If true, this row has a cell that should be expanded into its own full-width line
     */
    expand?: boolean;
    /**
     * The original item can be passed here.
     * Ideally this interface can be extended to give a more accurate type to this property.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalItem?: Record<string, any>;

    /**
     * Link for clickable
     */
    link?: string;

    /**
     * Key value pair for the table row
     */
    [key: string]: unknown;
}
